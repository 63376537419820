// export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";
export const TOKEN_TYPE = "Bearer ";

//Dev Server API URL
// export const BASE_URL = "https://buisapi.brainware-india.net";
// export const ASSETS_BASE_URL = "https://buisapi.brainware-india.net/upload/";
// export const CI_BASE_URL = "https://brainware-india.net/bwu-buis-capi";
// export const CI_ASSETS_BASE_URL = "https://brainware-india.net/bwu-buis-capi/uploads/";
// export const L_BASE_URL = "https://buislapi.brainware-india.net/api";

// Local API URL
// export const BASE_URL = "http://192.168.5.106/react-api";
// export const ASSETS_BASE_URL = "http://192.168.5.106/upload/";
// export const CI_BASE_URL = "https://brainware-india.net/bwu-buis-capi";

// Local API URL
// export const BASE_URL = "http://192.168.5.156/bwu-buis-api";
// export const ASSETS_BASE_URL = "http://192.168.5.156/upload/";
// export const CI_BASE_URL = "http://192.168.5.156/bwu-buis-capi";
// export const CI_ASSETS_BASE_URL = "http://192.168.5.156/bwu-buis-capi/uploads/";
// export const L_BASE_URL = "https://buislapi.brainware-india.net/api";

// Local Server API URL
// export const BASE_URL = "http://192.168.5.121/buis-bwu-api";
// export const ASSETS_BASE_URL = "http://192.168.5.121/upload/";
// export const CI_BASE_URL = "http://192.168.5.121/bwu-buis-capi";

// Dev Server URL


export const BASE_URL = "https://brainware-india.net/bwu-buis-api";
export const ASSETS_BASE_URL =
  "https://brainware-india.net/bwu-buis-api/upload/";
export const CI_BASE_URL = "https://brainware-india.net/bwu-buis-capi";
export const CI_ASSETS_BASE_URL =
  "https://brainware-india.net/bwu-buis-capi/uploads/";
export const L_BASE_URL = "https://buislapi.brainware-india.net/api";
export const EXAM_L_BASE_URL="https://examlapi.brainware-india.net/api";
export const STUDENT_L_BASE_URL="https://studentlapi.brainware-india.net/api";

export const GOOGLE_CAPTURE_KEY = "6LdJNMMpAAAAAI_dl9qX9bz8Actyjvy9RFP4to4n";

export const SESSION_EXPIRY_MINUTES = 120;
